@import '../../variables.scss';
@import '../../mixins.scss';

.projectItemsBg {
    height: 400px;
    max-width: 330px;
    border-radius: 12px;
    background-size: cover;
    background-position: center center;
    position: relative;
    // &:before{
    //     content: "";
    //     position: absolute;
    //     transform: scale(0);
    //     transition: all .4 ease-in-out;
    //     opacity: 0.9;
    // }
    // &:hover::before{
    //     transform: scale(10);
    // }
    .hover-items{
        position: absolute;
        top: 70%;
        left: 50%;
        border-radius: 12px;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        background-color: rgba(26, 25, 25, 0.801);
        padding: 2rem;
        visibility: hidden;
        display: flex;
        justify-content: space-around;
        li{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            a{
                color: $primary-light-color;
                width: 30px;
                height: 30px;
                border: 2px solid $primary-light-color;
                border-radius: 45px;
                padding: 2rem;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.4em;
                letter-spacing: 0.05em;
            }
        }
    }
    &:hover{
        .hover-items{
            visibility: visible;
            // transition: all 0.1s ease-in-out;
            top: 50%;
        }
    }    
}

.projectItems{
    margin: 1em auto;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    @include desktopLg{
        max-width: 1600px;
    }
}

.projectItemsImg{
    height: 400px;
    object-fit: cover;
    width: 100%;
    border-radius: 12px;
}

.mansonry {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    // order: 2;
    @include tablet{
        justify-content: space-evenly;
    }
}
.item {
    height: 100%;
    padding: 1em;
    margin: 1em;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    font-family: $secondary-font;
    text-transform: capitalize;
}

