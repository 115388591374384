@import '../../variables.scss';
@import '../../mixins.scss';

.about{
    width:100%;
    position: relative;
}

/*blob animation*/
.inhyeBgFilter{
    pointer-events: none; 
    position: absolute;
}
.inhyeBgWrapper{
    filter: url("#gooey-react");
}

svg *{
    transform-origin: 50%;
}

.inhyeBg-g{
    animation: 9s linear 0s infinite normal none running rotateBack;
}
#inhyeBg .c-1{
    animation: 12s ease-in-out -3s infinite alternate none running blob_four;
    fill: $secondary-purple-dark;
}
#inhyeBg .c-2{
    animation: 9s ease-in-out -3s infinite alternate none running blob_three;
    fill: $secondary-yellow;

}
#inhyeBg .c-3{
    animation: 6s ease-in-out -3s infinite alternate none running blob_two;
    fill: $secondary-purple-light;
}
#inhyeBg .c-4{
    animation: 3s ease-in-out -3s infinite alternate none running blob_one;
    fill: $secondary-purple;
}

@keyframes blob_four{
    100% {
        transform: rotate(-1turn) translate(-12.5%, -12.5%);
    }
}

@keyframes blob_three{
    100% {
        transform: rotate(-1turn) translate(-12.5%, 12.5%);
    }
}

@keyframes blob_two{
    100% {
        transform: rotate(-1turn) translate(12.5%, -12.5%);
    }
}

@keyframes blob_one{
    100% {
        transform: rotate(-1turn) translate(-12.5%, -12.5%);
    }
}

@keyframes left {
    100% { transform: rotate(-360deg) }
}

@keyframes right {
    100% { transform: rotate(360deg) }
}

@keyframes rotateBack{
    100% {
        transform: rotate(-1turn);
    }
}

@keyframes rotate{
    100%{
        transform: rotate(1turn);
    }
}

#inhye-txt{
    position: absolute;
    top: -20%;
    right: 5%;
    background-color: transparent;
    @include tablet{
        top: -7%;
    }
    @include desktop{
        top: -18%;
    }
    @include desktopLg{
        right: 0;
    }
}

/*title animation*/
.aboutHeader {
    width: 300px;
    height: 400px;
    div{
        position: relative;
        overflow:hidden;
        height:100%;
}

    h1,h2{
        font-size: 2em;
        text-transform: uppercase;
        font-family: $main-alternate-font;

    }

    @include tablet {
        width: 375px;

        h1,h2 {
            font-size: 2em;
        }
    }

    @include desktopLg{
        h1,h2{
            font-size: 1.7em;
        }
    }

    h1,
    h2 {
        span {
            width: 100%;
            float: left;
            color: $primary-dark-alternative;
            text-shadow: 1px 1px #ffffff;
            clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
            transform: translateY(-50px);
            opacity: 0;      
          }
    }
    h1 span{
        animation: titleAnimation 3s ease;
    }
    h2 span{
        animation: titleAnimation 3s ease;
    }

    h1 span {
        animation-delay: 0.6s;
        &:first-child {
            animation-delay: 0.7s;

        }

        &:last-child {
            color: $secondary-orange;
            animation-delay: 0.5s;
        }
    }
    h2{ 
        top: 0;
        position: absolute;
        span{
          animation-delay: 4.1s;
      
          &:first-child{
            animation-delay: 4.2s;
    
          }
      
          &:last-child{
            color: $secondary-orange;
            animation-delay: 4s;
          }
        }
    }
}

/*Credit to Robin Treur*/
@keyframes titleAnimation {
    0% {
      transform: translateY(-50px);
      opacity: 0;
      -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
      clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
  }
  20% {
      transform: translateY(0);
      opacity: 1;
      -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
      clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
  }
  80% {
       transform: translateY(0);
       opacity: 1;
      -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
      clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
  }
  100% {
       transform: translateY(50px);
       opacity: 0;
      -webkit-clip-path: polygon(100% 0, 100% -0%, 0 100%, 0 100%);
      clip-path: polygon(100% 0, 100% -0%, 0 100%, 0 100%);
    
  }
   
}

#inhyeBg{
    width: 100vw;
    height: 50vh;

    @include mobile {
        height: 600px !important;
    }

    @include desktop {
        height: 600px !important;
    }
}

.resumeBtn{
    background: $secondary-yellow;
    border: 3px solid $secondary-yellow;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    text-transform: capitalize;
    height: 56px;
    width: 178px;
    margin: 2em 3em;
    font-size: 1.3em;
    @include tablet{
        margin: 2em auto;
    }
}