@import '../../variables.scss';

.socialBar {
    background-color: $primary-light-alternative2;
    position: fixed;
    right: 3%;
    top: 36.5%;
    height: 200px;
    width: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 50px;
    // box-shadow: 0px 0px 1px $primary-color;
    z-index:10; 
}

.socialBar a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin: 0.5em;
    background-color: $primary-light-alternative2;
    transition: all 0.3s ease-in-out;
}

.socialBar .fa {
    padding: .5em;
    line-height: 50px;
    margin: .5em;
    width: 35px;
    height: 35px;
    position: inherit;
    color: $secondary-blue;
    filter: drop-shadow(0 0 7px $primary-light-alternative1);
}

.socialBar a:hover,
.socialBar a:active {
    transform: scale(1.5);
    transition: all 0.3s ease-in-out;
}
