@import '../../variables.scss';
@import '../../mixins.scss';

.scrollTopBar{
    position: fixed;
    right: 3.5%;
    bottom: 3%;
    z-index:10; 

    @include mobile{
        display: none;
    }
    @include tablet{
        display: block;
    }
    button{
        background-color: rgba(253, 253, 253, 0.3);
        height: 40px;
        width: 40px;
        border-radius: 50%;
        margin-bottom: 2em;
        outline: none;
        // box-shadow: 0px 0px 2px $primary-color;

        @include tablet{
            margin-bottom: 0;
        }
    }
}

.scrollTopBar .fa{
    color: $secondary-blue;
}

.scrollTopBar .fa-a-up{
    font-size: 1.5em; 
}