@import './variables.scss';

@mixin mobile {
    @media (min-width: $mobile-breakpoint) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: $tablet-breakpoint) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: $desktop-breakpoint) {
        @content;
    }
}

@mixin desktopLg {
    @media (min-width: $desktopLg-breakpoint) {
        @content;
    }
}

