@import './variables.scss';
@import './mixins.scss';
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap");

.App {
    background-color: var(--background-color);
    background-size: 100% 100%;
    min-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include tablet{
        background-size: 100% 100%;
    }
}

.middle {
    margin-bottom: 5rem;
    display: none;
}

p,
a {
    text-transform: uppercase;
}

.scrollScene-1{
    display: none;
}

.scrollmagic-pin-spacer{
    width: 100vw;
}

//test on Github Webhook