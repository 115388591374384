@import 'variables';

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  word-break: keep-all;
}

img {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

a {
  text-decoration: none;
  color: var(--secondary-blue);
}
ul,
li {
  list-style: none;
}
.cf:after {
  content: '';
  display: table;
  clear: both;
}

html, body, a, label{
    cursor: none;
}

body {
  // height: 100vh;
  font-family: $secondary-font;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  background-color:var(--background-color);
  color:var(--color);
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $main-font;
}

h2{
    font-weight: 600;
    color: $primary-dark-color !important;
    font-size: 1.5em; 
    letter-spacing: 0.02em;
    @media (max-width: 900px) {
        font-size: 1.5em;
    }
    @media (max-width: 650px) {
        font-size: 2.3em;
    }
}

::-webkit-scrollbar {
  display: none;
}
