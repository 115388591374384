$mobile-breakpoint: 560px;
$tablet-breakpoint: 650px;
$desktop-breakpoint: 992px;
$desktopLg-breakpoint: 1200px;

$primary-dark-color: rgb(15, 0, 39);
$primary-light-color: rgb(241, 239, 245);
$primary-color: #111111;
$primary-light-alternative1: rgb(253, 253, 253);

$primary-light-alternative2: rgba(253, 253, 253, 0.3);
$primary-dark-alternative: #654310;

$secondary-blue: #2E93EA;
$secondary-orange: #E65722;
$secondary-purple: rgb(163, 34, 238);
$secondary-purple-light: rgb(208, 129, 224);
$secondary-purple-dark: rgb(166, 57, 230);
$secondary-light: #F9f9f9;
$secondary-yellow: rgb(247, 193, 15);
$shadow-color: rgba(80, 80, 80, 0.2);
$secondary-peach: #e08a81;

$button-color: #6c0faa;

$error-color: #923634;
$error-bg-color: #f3e4e3;

$dark-blue: #213354;
$light-gray: #707070;
$grey-blue: #9ea7c5; //575e75

$link: #fafafa;
$text: #fafafa;

$main-font: "Poppins", sans-serif;
$secondary-font: "PT Sans", sans-serif;
$secondary-alternate-font: "Geomanist", Helvetica, sans-serif;
$main-alternate-font: 'Gochi Hand', sans-serif;
$font-color: #131313;
$heading-color: #2b2525;


$copyright: #215b8d;