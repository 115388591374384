@import '../../variables.scss';

.formBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1em;
}

.loginBox {
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loginBox button {
    padding: 0.4em;
}

.nameBox {
    margin: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.nameBox p {
    color: $text;
    font-size: 0.8em;
}

.textBox {
    height: 32px;
    width: 200px;
    border-radius: 3px;
    padding-left: 20px;
}

.submitBox {
    height: 30px;
    width: 30px;
    margin-left: 1.5em;
}

.errorName {
    color: $error-color;
    background-color: $error-bg-color;
    border: 0.1em solid $error-color;
    border-radius: 0.4em;
    line-height: 1.8;
    padding: 1.5em 1em;
    font-size: 0.6em;
    margin-top: 10px;
}