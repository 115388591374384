@import '../../variables.scss';
@import '../../mixins.scss';



.colorPalette{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 4rem auto;
}

.card{
    width: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    margin: 2rem;
    border-radius: 17px 17px 0 0;
    box-shadow: 0 0 0.6em rgba(0,0,0,0.2);
    @include tablet{
        width: 25%;
    }
}

.colorBg{
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.colorBgWrapper-1{
    display:flex;
    width: 100%;
}

.colorBg-1{
    border-radius: 17px 0 0 0;
    background-color: $secondary-orange;
    width: 50%;
}

.colorBg-1-2{
    border-radius: 0 17px 0 0;
    background-color: #973714;
    width: 50%;
    height: 100px;
}

.colorTitle{
    font-size: 0.8em;
    font-weight: 600;
}

.colorDescr{
    font-size: 0.7em;
    color:rgb(155, 153, 153);
}

.colorTxtWrapper-1{
    padding: 10px;
    width: 100%;
    background-color: rgb(255, 255, 255);
}

.colorBg-2{
    border-radius: 50%;
    background-color: $secondary-blue;
    width: 100px;
    height: 100px;
}

.colorBg-3{
    background-color: $secondary-purple;
}

.designGdTxt{
    text-align: center;
}