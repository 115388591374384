@import '../../variables.scss';
@import '../../mixins.scss';

header{
width: 100%;
position: absolute;
top: 0;
z-index: 10;
}
.header {
    padding: 0px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--background-color);
    color: var(--color);
    box-shadow: 0.1em 0.1em 0.3em rgba(0,0,0,0.1);
    height: 85px;
    @include tablet{
        padding: 0px 40px;
        height: 140px;
    }
  }
  .logo-nav {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 1em 0;
    @include tablet{
        width: 100%;
        justify-content: space-between;
    }
  }
  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logo {
    height: 80px;
    width: 80px;
    border-radius: 5px;
    @include tablet{
        width: 120px;
        height: 120px;
    }
  }
  .nav-options {
    padding-right: 25px;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 50px;
    list-style-type: none;
    height: 100%;
  }
  .mobile-option {
    display: none;
  }
  
  .signin-up {
    display: flex;
    padding: 0px 5px;
    list-style-type: none;
  }
  .sign-in {
    padding-right: 50px;
    align-self: center;
  }
  .sign-in :hover {
    color: white;
  }
  .signup-btn {
    padding: 10px 10px;
    height: 2.4rem;
    border-radius: 3px;
    background: rgb(222, 9, 241);
    color: white;
    cursor: pointer;
    align-items: center;
  }
  .mobile-menu {
    display: none;
  }
  @media (max-width: 648px) {
    /*Mobile View */
    .nav-options {
      display: flex;
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 115px;
      left: -100%;
      opacity: 0;
      transition: all 0.5s ease;
      flex-direction: column;
      list-style-type: none;
      grid-gap: 0px;
    }
    .nav-options.active {
      background: #a688ad;
      height: 100vh;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
      align-content: center;
      padding-left: 0px;
    }
  
    .menu-icon {
      width: 45px;
      height: 45px;
      background-color: #ffffff;
      border-radius: 5px;
    }
    .option {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 10vw;
      padding: 30px 0px;
    }
    .sign-up {
      background: rgb(222, 9, 241);
      border-radius: 3px;
      color: white;
      padding: 20px 0px;
      width: 80%;
      align-self: center;
      border-radius: 3px;
    }
  
    .signin-up {
      display: none;
    }
    .mobile-menu {
        display: block;
      }
  }

header .menuWrapper .menuNav .menu p:nth-child(1):hover ~ .menuLine{
    left: 0;
}

header .menuWrapper .menuNav .menu p:nth-child(2):hover ~ .menuLine{
    left: 20%;
}

header .menuWrapper .menuNav .menu p:nth-child(3):hover ~ .menuLine{
    left: 40%;
}

header .menuWrapper .menuNav .menu p:nth-child(4){
    @include desktop{
        width: 12%;
    }
}

header .menuWrapper .menuLine{
    transition: all 0.75s ease;
    background: $secondary-orange;
    position: absolute;
    width: 20%;
    height: 3px;
    margin-left: 0;
    bottom: 0;
    left: -20%;
}

.progress {
    height: 3px;
    width: 0%;
    background-color: $primary-light-color;
    position: fixed;
    top: 0;
    left: 0;
    animation: progress 1s linear;
    z-index:10;
  }
  @keyframes progress {
    to {
      background-color: $secondary-orange;
      width: 100%;
    }
  }
  
  :root *:before {
    /* Pause the animation */
    animation-play-state: paused;
    /* Bind the animation to scroll */
    animation-delay: calc(var(--scroll) * -1s);
    /* These last 2 properites clean up overshoot weirdness */
    animation-iteration-count: 1;
    animation-fill-mode: both;
  }
