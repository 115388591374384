@import '../../variables.scss';
@import '../../mixins.scss';

.projectItem{
  display:flex;
  flex-direction: column;
  align-items: center;
  background-color: #c9e7b5;
  p{
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

.singleProjectLink{
  padding-bottom:2em;
}
.singleProjectImg {
  height: auto;
  width: 100%;
  max-width: 900px;
  border-radius: 12px;
}

.projectItemImg {
  height: 400px;
  width: 100%;
  max-width: 900px;
  border-radius: 12px;
}

.projectItemImg-s{
  height: auto;
  width: 100%;
  object-fit: contain;
  border-radius: 12px;
  display: block;
}

.gallery{
  column-count: 1;
  column-gap: 10px;
  padding-left: 10px;
  padding-right: 10px;
  @include desktopLg{
    column-count: 3;
  }
  figure{
    margin: 0;
    // display: grid;
    grid-template-rows: 1fr auto;
    margin-bottom: 10px;
    break-inside: avoid;
  }
  figure > img{
      grid-row: 1 / -1;
      grid-column: 1;
  }
  img{
    max-width: 100%;
    display: block;
    border-radius: 10px;
  }
}

.gallery-s{
  column-count: 4;
  column-gap: 10px;
  padding-left: 10px;
  padding-right: 10px;
  figure{
    margin: 0;
    display: grid;
    grid-template-rows: 1fr auto;
    margin-bottom: 10px;
    break-inside: avoid;
  }
  figure > img{
      grid-row: 1 / -1;
      grid-column: 1;
  }
  img{
    max-width: 100%;
    display: block;
    border-radius: 10px;
  }
}

.carouselItems{
  width: 100%;
  position: relative;
  .carouselItemImgs{
    height: auto;
    width: 100%;
    border-radius: 12px;
    display: block;
    margin: 0 auto;
  }
  @include desktopLg{
    width: 600px;
  }
}
.carouselBtnBox{
  position: absolute;
  top: 33%;
  width: 100%;
  max-width: 600px;
  @include tablet{
    top: 38%;
  }
  @include desktopLg{
    top: 40%;
  }
  .carouselBtn{
    background: transparent;
  }
  .fa-a-left{
    width: 36px;
    height: 36px;
    color: $secondary-light;
    position: absolute;
    left: 0;
  }
  .fa-a-right{
    width: 36px;
    height: 36px;
    color: $secondary-light;
    position: absolute;
    right: 0;
  }
}

.projectTitle{
  font-family: $main-font;
  font-weight: 600;
  font-size: 1.2em;
}
.projectSubtitle{
  font-family: $main-font;
  font-weight: 300;
  font-size: 1.1em;
  text-align: center;
}
.projectTxt, .singleProjectTxt{
  letter-spacing: -0.5px;
  text-transform: initial;
  max-width: 800px;
}
.singleProjectTxt{
  padding-bottom: 0 !important;
}
.singleProjectBox{
  padding-left: 10px;
  padding-right: 10px;
}
.singleProjectBox, .projectBox{
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 1em;
}
.projectTxtBox{
  p{
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.carousel{
  max-width: 600px;
  margin: 0 auto;
}
.carousel__slider{
  height: auto;
  border-radius: 12px;
}