@import '../variables.scss';
@import '../mixins.scss';

.container{
    display: flex;
    width: 100%;
    margin: 10em auto 0 auto;
    
    @include tablet {
        height: auto;
        margin: 180px auto 0 auto;
        max-width: 900px;
    }
    @include desktop {
        max-width: 1100px;
    }
    @include desktopLg{
        max-width: 1500px;
    }
}

.container-m{
    display: flex;
    width: 100%;
    margin: 0 auto;
    padding-top: 8em;
    padding-bottom: 50px;
    background-color: #c9e7b5;
    
    @include tablet {
        height: auto;
        padding-top: 180px;
        margin: 0 auto;
    }
}

.container-l{
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    background-color: #c9e7b5;
    
    @include tablet {
        height: auto;
        margin: 0 auto;
        max-width: 900px;
    }
    @include desktop {
        max-width: 1100px;
    }
    @include desktopLg{
        max-width: 1500px;
    }
}

.title{
    width: 100%;
    margin: 2em auto;
    padding: 0 1em;
    font-size: 1em;
    font-weight: 300;
    display: block;
    @include desktop{
        font-size: 1.35em;
    }
}

.titlePage{
    display: none;
}

.siteMap{
    font-size: 1em;
    font-weight: 200;
}

.siteMap:active{
    color: $secondary-orange;
}